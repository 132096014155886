<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-data-table :headers="headers" dense :items="lista" sort-desc sort-by="cpepId" class="elevation-1">
                <template v-slot:top>

                    <v-toolbar>
                        <v-toolbar-title class="primary--text">Permisos</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-btn class="mx-2" tile color="primary" @click.stop="dialog = true">
                            agregar
                        </v-btn>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon fab color="info" v-on="on" class="mr-2" x-small @click.stop="dialog = true" @click="editar(item.cpepId)">
                                <v-icon>create</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small icon v-on="on" color="error" @click="eliminar(item.cpepId)">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <label class="primary--text">No hay datos disponibles</label>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-btn text class="mb-2 primary--text" @click="agregarHorario" v-if="isNew">Agregar nuevo</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-for="(items , index) in form" :key="index">
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined  dense :rules="nameRules" :error="$v.form.$each[index].cpepDias.$error" @input="cambiarFecha(items)" type="number" label="Dias" v-model="items.cpepDias"></v-text-field>
                            </v-col>
                           <v-col cols="12" sm="12" md="4">
                                <v-menu v-model="items.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field required dense outlined :error="$v.form.$each[index].cpepFechainicio.$error" v-model="items.cpepFechainicio" label="Fecha inicio" append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="items.cpepFechainicio" @input="items.model = false" @change="cambiarFecha(items)"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field required dense outlined :error="$v.form.$each[index].cpepFechafinal.$error" v-model="items.cpepFechafinal" label="Fecha final" append-icon="event" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <v-textarea dense outlined :error="$v.form.$each[index].cpepMotivo.$error" required v-model="items.cpepMotivo" label="Motivo"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="1" v-if="index>0">
                                <v-btn text icon color="error" v-if="isNew" @click="eliminarHorario(items)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>

</v-container>
</template>

<script>


import {
    required
}
from 'vuelidate/lib/validators';

export default {
    name: "EmpleadoPermiso",
    components: {
        
    },
    props: {
        coprId: Number,
    },
    data() {
        return {
            dialog: false,
            isNew: true,
            url: "sistema/empleadopermiso/",
            token: this.$cookies.get("token"),
            nameRules: [v => !!v || "Campo requirido"],
            
            form: [{
                cpepId: null,
                cpepDias: null,
                cpepFechainicio: null,
                cpepFechafinal: null,
                cpepMotivo: null,
                coprId: null,
            }],
            filtros: [
            ],
            headers: [{
                    text: "Id",
                    value: "cpepId"
                },
                {
                    text: "Dias",
                    value: "cpepDias"
                },
                {
                    text: "Fecha inicial",
                    value: "cpepFechainicio"
                },
                {
                    text: "Fecha final",
                    value: "cpepFechafinal"
                },
                 {
                    text: "Motivo",
                    value: "cpepMotivo"
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false
                }
            ],
            lista: [],
            listaCopia: []
        };
    },
    validations: {
        form: {
            $each: {
                cpepDias: {
                    required,
                },
                cpepFechainicio: {
                    required,
                },
                cpepFechafinal: {
                    required,
                },
                cpepMotivo:{
                    required,
                },
                coprId: {
                    required,
                },

            }
        },

    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        this.$store.commit('setCargaDatos', true);
        this.listaCopia = this.lista = await this.$apiService.index(this.url + "index/" + this.coprId).then(data => data);
        this.$store.commit('setCargaDatos', false);
        this.limpiar();
    },
 

    methods: {
        cambiarFecha(item) {
            if (item.cpepFechainicio && item.cpepDias) {
                item.cpepFechafinal = new Date(item.cpepFechainicio.replace(/-+/g, '/')).addDays((parseInt(item.cpepDias) - 1)).yyyymmdd();
            }
        },
        buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;

            /*if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                this.lista = this.listaCopia.filter(
                    e => e.cpehTipoDia == this.filtros[0].value
                );
            }
            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                this.lista = this.listaCopia.filter(
                    e => e.cpehTipoJornada == this.filtros[1].value
                );
            }
            if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
                this.lista = this.listaCopia.filter(
                    e => e.cpehTipoEstado == this.filtros[2].value
                );
            }*/

        },

        async agregar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    if (this.isNew) {
                        this.$apiService
                            .usercan(this.token, {
                                parent: this.$cookies.get("user").role,
                                child: "crearHorarios"
                            });
                        let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
                        if (data) {
                            for (var i = 0; i < data.length; i++) {
                                this.listaCopia = this.lista.push(data[i]);
                            }

                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    } else {
                        this.$apiService
                            .usercan(this.token, {
                                parent: this.$cookies.get("user").role,
                                child: "editarHorarios"
                            });
                        var id = this.form[0].cpepId;
                        let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form[0]).then(data => data);
                        if (data) {
                            this.lista = this.lista.filter(e => e.cpepId != id);
                            this.listaCopia = this.lista.push(data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    }
                    this.close();
                } catch (error) {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            }

        },
        async eliminar(id) {
            try {
                var result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
                    if (data) {
                        this.listaCopia = this.lista = this.lista.filter(
                            e => e.cpepId != id
                        );
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {
            try {
                let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
                if (data) {
                    this.form = [];
                    this.form.push(data);
                }

                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        agregarHorario() {
            this.form.push({
                 cpepId: null,
                cpepDias: null,
                cpepFechainicio: null,
                cpepFechafinal: null,
                cpepMotivo: null,
                coprId: this.coprId,
                modal2:false,
                modal1:false,
            });
        },
        eliminarHorario(item) {

            this.form = this.form.filter(e => this.form.indexOf(e) != this.form.indexOf(item));
        },
        limpiar() {
            this.form = [{
                cpepId: null,
                cpepDias: null,
                cpepFechainicio: null,
                cpepFechafinal: null,
                cpepMotivo: null,
                coprId: this.coprId,
                modal2:false,
                modal1:false,
            }];
        },
        close() {
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>

<style>

</style>
